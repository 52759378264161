.multisearch-dropdown {
  & > .ui.label.multisearch-item {
    display: inline-flex;
  }
  .selected-item {
    display: inline-flex;
    .avatar-image {
      max-height: 2.1em;
      max-width: 2.1em;
      margin-right: 5px;
    }
  }
}
