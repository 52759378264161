@use "variables";

.competition-results {
  .events-list {
    display: flex;
    justify-content: center;
  }
  .results-data {
    .table-cell-solves {
      word-spacing: 0.5em;
      vertical-align: middle;
    }
    .table-cell-solves-mbf {
      word-spacing: 2em;
      vertical-align: middle;
    }
    .WR {
      color : variables.$wr-color;
    }
    .CR {
      color : variables.$cr-color;
    }
    .NR {
      color : variables.$nr-color;
    }
    .edit-link {
      margin-left: 1em;
    }
  }
  .prewrap {
    white-space: pre-wrap;
  }
}
