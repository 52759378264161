.user-avatar-image,
.user-avatar-image-small {
  display: inline-block;
  vertical-align: middle;

  overflow: hidden;

  width: 50px;
  height: 50px;

  &.rounded {
    border-radius: 0.3em;
  }
}

.user-avatar-image-tiny {
  @extend .user-avatar-image;
  width: 40px;
  height: 40px;
}

.user-avatar-image-medium {
  @extend .user-avatar-image;
  width: 75px;
  height: 75px;
}

.user-avatar-image-large {
  @extend .user-avatar-image;
  width: 100px;
  height: 100px;
}
