@use "variables";

.user-badge {
  margin: 4px !important;

  &>.label {
    // Same colour as the rest of the button, so that it blends without a border.
    // It should normally be hidden anyway, but for the users with multiple lines
    // of subtext, it shows a little.
    background-color: #e0e1e2 !important;
  }

  &.leader {
    .user-badge-subtext {
      color: variables.$dark-blue;
    }
  }

  &.senior {
    .user-badge-subtext {
      color: variables.$dark-green;
    }
  }

  &.left-align {
    .user-name {
      text-align: start;
    }
  }

  .user-name {
    background-color: transparent !important;
    width: 100%;

    display: flex !important;
    align-content: center;
    justify-content: center;
    flex-direction: column;

    .user-badge-subtext {
      font-style: oblique;
      margin-top: 6px;
      font-size: 0.9em;
    }
  }

  .show-border {
    border: 1px solid #e0e1e2 !important;
  }

  /* Stop this looking like you can click it */
  .user-name-no-link {
    pointer-events: none;
  }

  .icon {
    margin: 0 0.25em;
  }

  .user-badge-label-avatar {
    padding: 0 !important;
  }

  .user-avatar-rounded-left {
    border-radius: 0.3em 0 0 0.3em;
  }
}
