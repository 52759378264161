.cubing-icon {
    cursor: pointer;
    font-size: 2em;
    padding: 0.2em;
  }
.cubing-icon:hover {
    color: #808080;
  }

.selected {
    color: #fc4a0a;
  }

.event-menu-bar {
  flex-wrap: wrap;
}